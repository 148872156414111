<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>
    <b-card
      no-body
      class="m-0"
    >
      <b-card-header>
        <b-card-title>Ý nghĩa {{ YNghiaCacConSoData.nhom.label }} số {{ YNghiaCacConSoData.chi_so }}</b-card-title>
      </b-card-header>

      <div
        class="p-2 pt-0"
        v-html="YNghiaCacConSoData.noi_dung"
      />
      <div class="demo-inline-spacing p-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          :to="{name: 'sua-y-nghia-cac-con-so', params: {id: YNghiaCacConSoData.id}}"
        >
          <feather-icon icon="EditIcon" />
          Sửa
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          :to="{name: 'y-nghia-cac-con-so'}"
        >
          <feather-icon icon="ArrowLeftCircleIcon" />
          Quay lại danh sách
        </b-button>
      </div>

    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BCard, BCardHeader, BCardTitle, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import yNghiaCacConSoStoreModule from '@/views/y-nghia-cac-con-so/yNghiaCacConSoStoreModule'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BButton,
    BCard,
  },
  setup() {
    const blankYNghiaCacConSoData = {
      id: '',
      chi_so: '',
      noi_dung: '',
      nhom: '',
      chu_de: '',
    }

    const YNghiaCacConSoData = ref(JSON.parse(JSON.stringify(blankYNghiaCacConSoData)))

    const USER_APP_STORE_MODULE_NAME = 'app-y-nghia-cac-con-so'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, yNghiaCacConSoStoreModule)

    const currentUser = getUserData()
    const toast = useToast()
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }
    store.state.showBlock = true

    store.dispatch('app-y-nghia-cac-con-so/loadModel', {
      id: router.currentRoute.params.id,
      auth: currentUser.auth_key,
      uid: currentUser.id,
    })
      .then(response => {
        YNghiaCacConSoData.value = response.data
        store.state.showBlock = false
      })
      .catch(error => {
        if (error.response.status === 404) {
          showToast('danger', 'Thông báo', 'BellIcon', error.response.message)
        }
        store.state.showBlock = false
      })
    return {
      YNghiaCacConSoData,
      showToast,
    }
  },
}
</script>

<style scoped>

</style>
